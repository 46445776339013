<template lang="pug">
  Transition(
    name="fade"
    tag="div"
    class="transition-container"
  )
    Confirm.notification-modal(
      v-if="isOpen"
      key="confirm"
      :title="notificationData.title"
      :show-cancel="false"
      @resolve="handleResolve"
    )
</template>

<script>
  // misc
  import { bus } from "@/config"

  const defaultData = {
    title: "",
    handler: () => {}
  }

  export default {
    components: {
      Confirm: () => import("@/components/elements/AppConfirm/Confirm")
    },
    data() {
      return {
        isOpen: false,
        notificationData: { ...defaultData }
      }
    },
    created() {
      bus.on("open-notify-dialog", ({ title, handler = () => {} }) => {
        if (!title) {
          throw new Error('"title" is required')
        }
        this.notificationData = { ...defaultData, title, handler }
        this.isOpen = true
      })
    },

    methods: {
      close() {
        this.isOpen = false
      },

      handleResolve() {
        this.notificationData.handler()
        this.close()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/transitions.sass"

  .notification-modal
    ::v-deep
      .modal-confirm-wrapper
        .modal-confirm-body
          .header
            .title
              text-align: justify

        .modal-confirm-footer
          justify-content: space-around
</style>
